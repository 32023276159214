<template>
    <b-row class="m-0">
        <header class="header headeratt">
            <b-navbar class="navbar px-0">
                <div>
                    <div class="fullscreenleftdiv">
                        <!-- <router-link to="/"><b-img class="fullscreenlogo" src="https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Ffavicon.ico?alt=media&token=225b4fd7-89bc-4376-8396-51e7545a81d9"></b-img></router-link> -->
                        <!-- <router-link to="/"><b-img class="fullscreenlogo" src="https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Ftarifando_logo_icon.png?alt=media&token=c9e8ed03-72b5-458a-8cd8-87a731c38a6a"></b-img></router-link> -->
                        <div>
                            <!-- <b-img class="fullscreenlogo" :src="require(`../assets/images/logomarca/icone.png`)"></b-img> -->
                            <b-img class="fullscreenlogo" :src="verifyPhotoURL(user.iconURL)" @click="logoClick"></b-img>
                        </div>
                        <span class="date">
                            <span class="datetime" v-if="date">Hoje: <span class="text-capitalize mx-1">{{date.dia}}</span> • {{date.hora}}</span> 
                            <span class="datebtnwrapper" id="workTime">
                                <b-icon class="datebtnicon" icon="chevron-down" ></b-icon>
                                <b-spinner class="datespinner"></b-spinner>
                            </span>
                            <b-tooltip target="workTime" triggers="hover" custom-class="mt-1 top-0" boundary="document" variant="light">
                                Horário de atendimento das
                                <span v-if="date.weekDay == 7">
                                    {{ `${operator.sunStartTime} às ${operator.sunFinishTime}` }}
                                </span>
                                <span v-else-if="date.weekDay == 6">
                                    {{ `${operator.satStartTime} às ${operator.satFinishTime}` }}
                                </span>
                                <span v-else>
                                    {{ `${operator.weekStartTime} às ${operator.weekFinishTime}` }}
                                </span>
                            </b-tooltip>
                        </span>
                        <span class="form-check form-switch position-relative">
                            <b-form-checkbox id="statusSwitch" v-model="operator.status" name="check-button" @change="switchOperatorStatus()"></b-form-checkbox>
                            <label class="d-flex" for="statusSwitch">
                                <span class="position-absolute text-white" style="top: .5em; right: 2.1em" role="button">
                                    <span class="switchText">
                                        <span v-if="operator.status">Online</span>
                                        <span v-else>Offline</span>
                                    </span>
                                </span>
                            </label>
                        </span>

                    </div>
                </div>
                <b-navbar-nav>
                    <span class="navitemscenter me-1">
                        <div class="navitems" v-for="item in attendanceicons" :key="item.id">
                            <span class="iconstackwrapper fullscreenbtn" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-journal top-0', boundary: 'document' }" title="Expandir/Minimizar Tela" v-on:click="openFullscreen()" v-if="item.name=='arrows-angle-expand'">
                                <b-icon class="navicon naviconatt" :icon="item.name" v-if="!fullscreen"></b-icon>
                                <b-icon class="navicon naviconatt" icon="arrows-angle-contract" v-else></b-icon>
                            </span>   
                            <span class="iconstackwrapper" v-b-tooltip.hover.bottom.v-light="{ customClass: 'tooltip-journal top-0', boundary: 'document' }" title="Meu Agendamento" v-on:click="$emit('operatorscheduling')" v-else-if="item.name=='journal-text'">
                                <b-icon class="navicon naviconatt" :icon="item.name"></b-icon>
                                <div class="iconnotifications rounded-circle">{{item.notificationsnumber}}</div>                        
                            </span>   
                            <span class="iconstackwrapper" v-else>
                                <b-icon class="navicon naviconatt" :icon="item.name"></b-icon>
                                <div class="iconnotifications rounded-circle">{{item.notificationsnumber}}</div>                        
                            </span>   
                        </div>
                        <b-nav-text class="mx-2 message pe-none">
                            <span class="usermsg usermsgatt">{{ message }}</span>
                            <span class="username usernameatt text-capitalize" :style="isTarifando() ? style.messageColor : ''">{{ user.operator?.name || user.name }}</span>
                        </b-nav-text>
                        <b-nav-text class="icon text-white d-flex align-items-center darkenTextOnHover ms-2" role="button" @click.prevent="logout" v-if="!operator?.channelConfig?.modules?.logoffRequiresAttendancesToFinish || !user?.attendancesCount">
                            <b-icon font-scale="1.25"  icon="power" v-if="window.innerWidth >= 425"></b-icon>
                            <b-icon font-scale="1.50" icon="power" v-else></b-icon>
                            <span class="ms-1" v-if="window.innerWidth >= 425">
                                Sair
                            </span>
                        </b-nav-text>
                    </span>
                    
                    <!-- <b-nav-item class="navitems">
                        <div class="useravatar">
                            <img class="usericon" :src="user.photoURL" v-if="user.photoURL">
                            <img class="usericon" src="https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Fdefault.jpg?alt=media&token=73be2b6f-a8a3-4407-b079-ab3918d37d8c" v-else>
                            <div class="avatardrop">
                                <div class="avatardroptriangle"></div>
                                <div class="avatardroplinkdiv" @click.prevent="selectUser" v-b-modal.modal-profile>
                                    <b-icon class="avatardropicon" icon="person"></b-icon>
                                    <a class="avatardroplink">
                                        Meu Perfil
                                    </a>
                                </div>
                                <div class="avatardroplinkdiv" @click.prevent="logout">
                                    <b-icon class="avatardropicon" icon="power"></b-icon>
                                    <a class="avatardroplink">
                                        Sair
                                    </a>
                                </div>
                            </div>
                        </div>
                    </b-nav-item> -->
                </b-navbar-nav>
            </b-navbar>
        </header>
        <b-modal id="modal-profile" ref="modal-profile" size="lg" header-class="attheadermodalheader" body-class="py-0" content-class="attheadermodalcontent" hide-footer v-if="arrayAux">
            <template #modal-title>
                <b-icon icon="people"></b-icon>
                <span class="px-2">Meu Perfil</span> 
            </template>
            <form @submit.prevent="editProfile">
                <b-row class="pb-3">
                    <b-col class="usercol1">
                        <b-row class="userinforow py-3 mx-1">
                            <b-col cols="2" class="clientstatscol clientstatscol1">
                                <span class="profileuseravatar position-relative">
                                    <b-form-file
                                    class="inputavatar"
                                    @input="setChannelAvatar"
                                    >
                                        <template #placeholder>
                                            <img class="clientstatsavatar rounded-circle" :src="arrayAux.photoURL" v-if="arrayAux.photoURL">
                                            <img class="clientstatsavatar rounded-circle" src="https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Fdefault.jpg?alt=media&token=73be2b6f-a8a3-4407-b079-ab3918d37d8c" v-else>
                                            <div class="usersemicircle">
                                                <b-icon icon="image-fill"></b-icon>
                                                <div>Atualizar</div> 
                                            </div>
                                        </template>
                                        <template #file-name>
                                            <img class="clientstatsavatar rounded-circle" :src="arrayAux.photoURL" v-if="arrayAux.photoURL">
                                            <div class="usersemicircle">
                                                <b-icon icon="image-fill"></b-icon>
                                                <div>Atualizar</div> 
                                            </div>
                                        </template>
                                    </b-form-file>
                                </span>
                            </b-col>
                            <b-col class="clientstatscol">
                                <div>
                                    <h5 class="profileusername m-0">
                                            {{arrayAux?.name}}
                                    </h5>
                                    <div class="profileuserjob">{{arrayAux.job}} {{arrayAux.company}}.</div>
                                    <b-rating class="p-0" v-model="arrayAux.stars" no-border inline variant="warning"></b-rating>
                                </div>
                            </b-col>
                        </b-row>
                        <b-form-group class="my-2 mt-3" label="Nome Completo:">
                            <b-form-input v-model="arrayAux.name"></b-form-input>
                        </b-form-group>
                        <b-row>
                            <b-col class="mt-2">
                                <b-form-group label="CPF:">
                                    <b-form-input v-model="arrayAux.cpf"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2">
                                <b-form-group label="Telefone:">
                                    <b-form-input v-model="arrayAux.mobile"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="py-3">
                        <b-form-group label="Email:">
                            <b-form-input v-model="arrayAux.email"></b-form-input>
                        </b-form-group>
                        <b-form-group class="my-2" label-class="px-1" label="Alerta Sonoro:">
                            <b-form-checkbox v-model="arrayAux.soundAlert" class="col-form-label m-0 p-0"><div class="px-2">Sim</div></b-form-checkbox>
                        </b-form-group>
                        <b-row>
                            <b-col>
                                <b-form-group label="Mudar Apelido:">
                                    <b-form-input v-model="user.nickname"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Mudar Senha:">
                                    <b-input-group>
                                        <template #append>
                                            <b-input-group-text class="inputappend py-2" v-b-tooltip.hover.bottom title="Visualizar Senha"><b-icon icon="eye"></b-icon></b-input-group-text>
                                        </template>
                                        <b-form-input v-model="arrayAux.password" type="password"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="profilebtns mt-3">
                            <b-button type="submit" class="savebtn">Salvar</b-button>
                            <b-button class="cancelbtn">Cancelar</b-button>
                        </div>
                    </b-col>
                </b-row>
            </form>
        </b-modal>
    </b-row>
</template>

<script>
import moment from 'moment';
import 'moment/locale/pt-br';
import { logout } from '../services/userService.js'
import api from '../services/apiService.js'

export default {
    props:[
        'fullscreen', 'socket', 'url', 'operator', 'mediaURL', 'user'
    ],
    created: function() {
        this.setTime();
        this.onCloseModal()
        this.getStyle()
        this.$root.$on('workTimeOver', (data) => {
            this.workTimeOver = data
        })
    },
    data() {
        return{
            arrayAux:null,
            elem: document.documentElement,
            attendanceicons: [
                // {
                //     name: 'volume-up',
                // },
                // {
                //     name: 'journal-text',
                //     notificationsnumber: 2,
                // },
                {
                    name: 'arrows-angle-expand',
                },
            ],
            message: "Olá, ",
            date:{
                dia: 'Jun 22',
                hora: '12:30:00',
            },
            startTime: '00:00',
            finishTime: '23:59',
            style: {headerBg: '',messageColor: ''},
            workTimeOver: false,
            window
        }
    },
    methods: {
        selectUser() {
            this.arrayAux = Object.assign({}, this.user);
        },
        setTime() {
            setInterval(() => {
                moment().locale('pt-br')
                if(this.date) {
                    this.date.hora = moment().format('LTS')
                    this.date.dia = moment().format('MMM DD')
                    this.date.weekDay = moment().isoWeekday()
                }
            })
        },
        async switchOperatorStatus() {
            if(this.workTimeOver) {
                return this.$emit('msg', {
                    text: 'Seu horário de atendimento foi finalizado!',
                    success: false
                })
            }
            const {_id,status} = this.operator
            const resp = await api.changeOperatorStatus(_id,status)
            console.log('resp changeOperatorStatus',resp)
            if(status && resp.statusCode == 200) {
                this.socket.emit('operator_status_update',{ operatorId: this.operator._id, status: this.operator.status })
                const attendances = resp.attendances
                if(attendances)
                    this.$root.$emit('attendances',attendances)
            } else if(status && resp.statusCode == 408) {
                this.$emit('msg', {
                    text: 'Seu horário de atendimento foi finalizado!',
                    success: false
                })
            }
        },
        openFullscreen() {
            if (!document.fullscreenElement &&    // alternative standard method
                !document.mozFullScreenElement && !document.webkitFullscreenElement) {
                if (this.elem.requestFullscreen) {
                    this.elem.requestFullscreen();
                } else if (this.elem.webkitRequestFullscreen) { /* Safari */
                    this.elem.webkitRequestFullscreen();
                } else if (this.elem.msRequestFullscreen) { /* IE11 */
                    this.elem.msRequestFullscreen();
                }
            }else{
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                }
            }
            this.$emit('fullscreen')
        },
        editProfile() {
            Object.keys(this.user).forEach((key) => {
                this.user[key] = this.arrayAux[key]
            })

            this.$emit('msg', {
                text:'Usuário editado com sucesso!',
                success: true,
            })

            this.$refs['modal-profile'].hide()
        },
        setChannelAvatar(e) {
            const url = URL.createObjectURL(e)
            this.arrayAux.photoURL = url
            URL.revokeObjectURL(url)
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.arrayAux = null
            })
        },
        logout() {
            logout(this.socket)
        },
        isTarifando() {
            if(this.url=='tarifando') return true
        },
        isStandard() {
            // if(this.url=='painel' || this.url.includes('localhost') || this.url=='dev') {
                return true
            // }
        },
        getStyle() {
            this.style.headerBg = {'background-color':'#15191d'}
            this.style.messageColor = {'color':'#ff9c41'}
        },
        verifyPhotoURL(url) {
            if(url) {
                if(!url.includes('http'))
                    return `${this.mediaURL}avatar/${url}`
                return url
            }
        },
        logoClick() {
            this.$root.$emit('clean_attendances', true)
        }
    },
}
</script>

<style>
    .fullscreenleftdiv .form-switch,.fullscreenleftdiv  .form-check{
        padding: 0;
        display: inline-block;
    }
    .fullscreenleftdiv .form-switch input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
    }
    .fullscreenleftdiv .form-switch .custom-control-label {
        cursor: pointer;
        text-indent: -9999px;
        width: 113px;
        height: 32px;
        background: hsl(10deg 79% 63%);
        display: block;
        border-radius: 100px;
        position: relative;
        margin-top: -20px;
        margin-left: 10px;
    }
    .fullscreenleftdiv .form-switch input:checked + .custom-control-label{
        background: hsl(87deg 55% 65%);
    }
    .fullscreenleftdiv .form-switch .custom-control-label:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 26px;
        height: 26px;
        background: #fff;
        border-radius: 28px;
        transition: 0.3s;
        z-index: 1;
    }
    .fullscreenleftdiv .form-switch input:checked + .custom-control-label:after {
        left: calc(100% - 3px);
        transform: translateX(-100%);
        background: #fff;
        z-index: 1;
    }
    body *{
        scrollbar-color: hsl(244deg 26% 80%) hsl(228deg 20% 95%);
        scrollbar-width: thin;
    }
    body *::-webkit-scrollbar{
       width: 12px;
    }
    body *::-webkit-scrollbar-track{
        background: hsl(228deg 20% 95%);
    }
    body *::-webkit-scrollbar-thumb{
        background-color: hsl(244deg 26% 80%);
    }
    .attheadermodalheader{
        padding: 10px 0 !important;
        color: #555;
        border-color: #eee !important;
    }
    .attheadermodalheader .b-icon{
        color: hsl(207deg 28% 38%);
        font-size: 1.25em;
    }
    .attheadermodalheader .close{
        border: none;
        color: #555;
        background-color: transparent;
        font-size: 2em;
        padding: 0;
    }
    .attheadermodalcontent{
        padding: 0 20px !important;
    }
    .attheadermodalcontent .col-form-label{
        color: hsl(250deg 15% 61%);
        font-size: 0.95em;
    }
    .tooltip-journal>.tooltip-inner{
        opacity: 100% !important;
    }
    .check-switch .custom-control-input:checked ~ .custom-control-label::before{
        border-color: hsl(87deg 55% 65%) !important;
        background-color: hsl(87deg 55% 65%) !important;
    }
    .check-switch .custom-control-label::before{
        border-color: hsl(10deg 79% 63%) !important;
        background-color: hsl(10deg 79% 63%) !important;
    }
    .check-switch .custom-control-label::after{
        background-color: #fff !important;
    }
    .check-switch .custom-control-label::before{
        height: 32px !important;
        width: 113px !important;
        border-radius: 1em !important;
    }
    .check-switch .custom-control-label::after{
        transition: 0.3s !important;
        margin-top:1px;
    }
    .custom-switch .custom-control-label::after{
        height: 26px !important;
        width: 26px !important;
        border-radius: 100% !important;
    }
    .custom-switch input:checked + label:after {
        left: 100%;
        transform: translateX(185%) !important;
        background: #fff;
        z-index: 1;
    }
    @media (max-width: 590px) {
        .fullscreenleftdiv .form-switch .custom-control-label {
            cursor: pointer;
            text-indent: -9999px;
            width: 4rem;
            height: 32px;
            background: hsl(10deg 79% 63%);
            display: block;
            border-radius: 100px;
            position: relative;
            margin-top: -20px;
            margin-left: 10px;
        }
    }
</style>

<style scoped>
    .header{
        background-color: hsl(248deg 50% 97%);
        width: 100%;
        /* padding-right: 125px; */
        z-index: 10;
        box-shadow: 8px 0px 5px #555;
    }
    .headerfullscreen{
        padding-right: 0;
    }
    .navbar{
        display: flex;
        justify-content: space-between;
    }
    .navitemscenter{
        display: flex;
        align-items: center;
    }
    .navitems{
        margin: 0 10px;
        cursor: pointer;
    }
    .navicon{
        font-size: 1.25em !important;
        color: hsl(231deg 39% 62%);
    }
    .navitems:hover .navicon{
        color: hsl(231deg 30% 50%);
    }
    .usermsg{
        color: hsl(231deg 11% 76%);
    }
    .username{
        color: hsl(231deg 39% 62%);
    }
    .usericon{
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 50%;
    }
    .avatardrop{
        position: absolute;
        background-color: #fff;
        border-radius: 3px;
        padding: 10px 0;
        margin-top: 12px;
        right: 0px;
        font-size: 0.8em;
        display: none;
    }
    .avatardroptriangle{
        position: absolute;
        height: 13px;
        width: 13px;
        transform: rotate(45deg);
        background-color: #fff;
        right: 30px;
        top: -7px;
    }
    .avatardroplink{
        color: hsl(207deg 18% 50%) !important;
    }
    .avatardroplinkdiv{
        padding: 5px 25px;
    }
    .avatardroplinkdiv:hover{
        background-color: #eee;
    }
    .avatardropicon{
        color: hsl(207deg 18% 50%);
        font-size: 2em;
    }
    .nav-item:hover .avatardrop{
        display: block;
    }
    .headeratt{
        background-color: hsl(244deg 21% 13%);
    }
    .naviconatt{
        color: #fff;
    }
    .navitems:hover .naviconatt{
        color: #aaa;
    }
    .iconstackwrapper{
        position: relative;
    }
    .iconnotifications{
        background-color: hsl(10deg 79% 63%);
        color: #fff;
        position: absolute;
        top: -10px;
        right: -5px;
        padding: 0px 5px;
        font-size: 0.6em;
    }
    .usermsgatt{
        color: #fff;
    }
    .usernameatt{
        color: cornflowerblue;
        font-weight: bold;
    }
    .date{
        color: #fff;
        border-radius: 25px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        padding: 0 20px;
        padding-right: 45px;
        display: inline-flex;
        align-items: center;
        position: relative;
    }
    .operatorscarddescription{
        color: #fff;
        position: absolute;
        top:7px;
        right: 33px;
        z-index: 0;
        text-align: center;
        cursor: pointer;
    }
    .switchwrapper{
        position: relative;
        display: inline-flex;
        align-items: center;
    }
    .datespinner{
        animation: none;
        transform: rotate(210deg);
        position: absolute;
        right: 0px;
    }
    .datebtnwrapper{
        position: absolute;
        right: 5px;
        top: 5px;
        color: hsl(10deg 79% 63%);
        width: 31px;
        height: 32px;
    }
    .datebtnicon{
        position: absolute;
        right: 9px;
        top: 10px;
        font-size: 0.8em;
        cursor: pointer;
        z-index: 1;
    }
    .fullscreenleftdiv{
        display: flex;
        align-items: center;
    }
    .fullscreenlogo{
        height: 50px;
        cursor: pointer;
    }
    .clientstatscol{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .clientstatscol1{
        align-items: center;
    }
    .clientstatsavatar{
        height: 70px;
        width: 70px;
    }
    .clientstatsname{
        font-size: 1.2em;
    }
    .b-rating{
        font-size: 0.8em;
        background-color: transparent;
        margin-top: 5px;
    }
    .profileusername{
        color: hsl(249deg 7% 35%);
    }
    .profileuserjob{
        color: hsl(247deg 13% 60%);
        font-size: 0.9em;
    }
    .userinforow{
        border-bottom: 1px solid #eee;
    }
    .usercol1{
        border-right: 1px solid #eee;
    }
    .profilebtns{
        padding-top: 2px;
    }
    .usersemicircle{
        position: absolute;
        bottom: -15%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 35px;
        width: 70px;
        border-radius: 0 0 150px 150px ;
        background-color: #333;
        color: #fff;
        opacity: 0.75;
        flex-direction: column;
        align-items: center;
        font-size: 0.7em;
        padding: 3px;
        display: none;
    }
    .profileuseravatar:hover .usersemicircle{
        display: flex;
    }
    .check-switch-text{
        right: -2.7em;
        top: 0.4em;
    }
     @media (max-width: 1023px) {
        .smallerscreendiv,.smallerscreenlogo{
            display: flex;
        }
        .header{
            padding: 0;
        }
    }
    @media(max-width: 768px) {
        .fullscreenbtn{
            display: none;
        }
        .navitems{
            margin: 0;
        }
        .navitems .b-icon{
            margin: 0 10px;
        }
        .iconnotifications{
            right: 5px;
        }
    }
    @media (max-width: 590px) {
        .fullscreenleftdiv .datetime {
            display: none;
        }
        .form-switch .switchText {
            display: none;
        }
        /* body > div > div > div:nth-child(2) > div > div:nth-child(1) > header > nav > div > div > span.form-check.form-switch.position-relative > div{
            transform: scale(0.5);
            
        } */
    }
</style>